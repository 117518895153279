import axios from "axios";
import { useState, useEffect } from "react";
import getCountryIcon from "../utils/getCountryIcon";
import CountryExpertiseIcon from "../assets/icons/CountryExpertiseIcon";
import SocialNetworksIcon from "../assets/icons/SocialNetworksIcon";
import FacebookIcon from "../assets/icons/FacebookIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import CameraIcon from "../assets/icons/CameraIcon";
import ImageGallery from "./ImageGallery";
import PersonIcon from '@mui/icons-material/Person';

const WhoAmITabPanel = ({ data, tabRef, userId, listingData }) => {
    const [countriesData, setCountriesData] = useState([]);
    const [userAvatar, setUserAvatar] = useState(null);

    // Fetch user avatar based on userId
    const getUserInfo = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/user-info/show/${listingData.author}`);
            setUserAvatar(response?.data?.user_avatar || null);
        } catch (err) {
            console.log("Error fetching user info: ", err.response);
        }
    };

    const getCountryData = async (countryId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/rz_country/${countryId}`);
            if (response) {
                return response?.data;
            }
        } catch (err) {
            console.log("err: ", err.response);
        }
    }

    const fetchCountriesData = async () => {
        const fetchedCountries = await Promise.all(
            data?.acf?.add_more_country.map(async (item) => {
                const countryData = await getCountryData(item?.rz_country_itinerary);
                return { ...item, countryData };
            })
        );
        setCountriesData(fetchedCountries);
    };

    useEffect(() => {
        if (listingData) {
            getUserInfo(); // Fetch the user info when component mounts
        }
        fetchCountriesData();
    }, [listingData]);

    const renderIcon = () => {
        if (userAvatar) {
            return <img src={userAvatar} alt="User Avatar" />;
        } else {
            return <img
                src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2023/04/d4k9unor1mhgja8l30e6qiyv75psxfzw-150x150.jpg`}
                alt="User Avatar"
            />

        }
    };

    return (
        <div className="tabPanel" key="whoAmI" ref={tabRef}>
            <div className="aboutTabPanel">
                <div className="parentDiv">
                    <div className="singleListingPanelTitleIcon d-flex">
                        <div className="title_icon">
                            {renderIcon()}
                        </div>
                        <h3>מי אני</h3>
                    </div>
                    <div className="singleListingPanelSummary tabDescription" >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data?.acf?.rz_about_yourself_worldview_on_trips_2,
                            }}
                        />
                        <button
                            className="who_am_btn btn btn-primary mt-30 mb-30"
                            onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/search-vacations/?author=VACAYTIONS`}
                        >
                            לדף המפרסם
                        </button>
                    </div>
                </div>
                <div className="parentDiv">
                    <div className="singleListingPanelTitleIcon d-flex">
                        <div className="title_icon">
                            <CountryExpertiseIcon />
                        </div>

                        <h3>מומחיות למדינות</h3>
                    </div>
                </div>
                {countriesData.map((item, key) => {
                    const countrySlug = item?.countryData?.slug;
                    const countryIconData = countrySlug ? getCountryIcon(countrySlug) : null;

                    return (
                        <div className="countriesData prentDiv" key={`countriesData-${key}`}>
                            <div className="singleListingPanelTitleIcon d-flex">
                                <div className="title_icon"
                                    dangerouslySetInnerHTML={{
                                        __html: countryIconData && countryIconData[countrySlug] ? countryIconData[countrySlug] : ""
                                    }}
                                />
                                <div className="singleListingPanelSummary tabDescription">
                                    <h5>{item?.countryData?.name || "No Country Name"}</h5>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item?.rz_briefly_write_down || "",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}


                <div className="socialNetworks">
                    <div className="singleListingPanelTitleIcon d-flex">
                        <div className="title_icon">
                            <SocialNetworksIcon />
                        </div>
                        <h3>רשתות חברתיות</h3>
                    </div>

                    <div className="singleListingPanelSummary d-flex">
                        <ul className="singleListingSocialIcons d-flex">
                            {data?.acf?.rz_facebook_check[0] == 1 && (
                                <li>
                                    <a href={data?.acf?.rz_facebook_url}>
                                        <FacebookIcon />
                                    </a>
                                </li>
                            )}
                            {data?.acf?.rz_instagram_check[0] == 1 && (
                                <li>
                                    <a href={data?.acf?.rz_instagram_url}>
                                        <InstagramIcon />
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>

                    <div className="picturesOfTrip">
                        <div className="singleListingPanelTitleIcon d-flex">
                            <div className="title_icon">
                                <CameraIcon />
                            </div>
                            <h3>תמונות מטיולים</h3>
                        </div>
                        <div className="singleListingPanelSummary d-flex">
                            <ImageGallery
                                images={data?.acf?.who_am_i_gallery}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default WhoAmITabPanel;