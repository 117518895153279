import React, { useEffect, useState } from "react";
import TripSummaryIcon from "../assets/icons/TripSummaryIcon";
import WhoAmINavIcon from "../assets/icons/WhoAmINavIcon";
import LodgingIcon from "../assets/icons/LodgingIcon";
import CulinaryIcon from "../assets/icons/CulinaryIcon";
import AttractionIcon from "../assets/icons/AttractionIcon";
import NightLifeIcon from "../assets/icons/NightLifeIcon";
import ShoppingIcon from "../assets/icons/ShoppingIcon";
import MapIcon from "../assets/icons/MapIcon";
import CalendarIconWithoutModal from "../assets/icons/CalendarIconWithoutModal";
import FAQIcon from "../assets/icons/FAQIcon";
import ReviewsIcon from "../assets/icons/ReviewsIcon";

const ScrollingTabs = ({
  summaryTab,
  whoAmITab,
  lodgingTab,
  culinaryTab,
  attractionTab,
  nightLifeTab,
  shoppingTab,
  mapTab,
  calendarTab,
  faqTab,
  reviewsTab,
  userId,
  listingData,
  showBlur,
}) => {
  const [currentActiveTab, setCurrentActiveTab] = useState("summary");
  const [width, setWidth] = useState(window.innerWidth);

  // All tab elements with their refs
  const tabs = [
    { name: "summary", ref: summaryTab, icon: <TripSummaryIcon />, label: "תקציר הטיול" },
    { name: "whoAmI", ref: whoAmITab, icon: <WhoAmINavIcon />, label: "מי אני" },
    { name: "lodging", ref: lodgingTab, icon: <LodgingIcon />, label: "לינה" },
    { name: "culinary", ref: culinaryTab, icon: <CulinaryIcon />, label: "קולינריה" },
    { name: "attraction", ref: attractionTab, icon: <AttractionIcon />, label: "אטרקציות" },
    { name: "nightLife", ref: nightLifeTab, icon: <NightLifeIcon />, label: "חיי לילה" },
    { name: "shopping", ref: shoppingTab, icon: <ShoppingIcon />, label: "שופינג" },
    { name: "map", ref: mapTab, icon: <MapIcon />, label: "מפה מפורטת" },
    { name: "calendar", ref: calendarTab, icon: <CalendarIconWithoutModal />, label: "לו\"ז לדוגמא" },
    { name: "faq", ref: faqTab, icon: <FAQIcon />, label: "שאלות ותשובות" },
    { name: "reviews", ref: reviewsTab, icon: <ReviewsIcon />, label: "ביקורות" },
  ];


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 641;

  // Scroll to section with sticky header offset
  const scrollToTab = (ref, tabName) => {
    const headerHeight = isMobile ? 170 : 200; // Adjust for header height
    const sectionTop = ref.current.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: sectionTop - headerHeight,
      behavior: "smooth",
    });

    // Set the clicked tab as active
    setCurrentActiveTab(tabName);
  };

  // Handle tab activation while scrolling
  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = isMobile ? 180 : 210;
      let currentTab = "summary"; // Default tab

      tabs.forEach(({ name, ref }) => {
        const sectionTop = ref.current.getBoundingClientRect().top - headerHeight;
        if (sectionTop <= 0) {
          currentTab = name;
        }
      });

      setCurrentActiveTab(currentTab);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tabs]);

  return (
    <div className="scrollingTabsWrapper">
      <div className="container d-flex align-items-start scrollingTabsContainer">
        <div className="scrollingTabsWrapperInner d-flex align-items-center">
          {tabs.map(({ name, ref, icon, label }) => (
            <div
              key={name}
              className={`singleScrollingTab ${currentActiveTab === name ? "active" : ""}`}
              onClick={() => scrollToTab(ref, name)}
            >
              <div className="d-flex justify-content-end align-items-center flex-column">
                <div className="navIcon">{icon}</div>
                <p className="mb-0">{label}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Login/Cart Button */}
        {userId === 0 ? (
          <a href={`${process.env.REACT_APP_API_URL}/login/?listing_id=${listingData.id}`} className="headerLoginBtn">
            לרכישה
          </a>
        ) : (
          showBlur && (
            <a href={`${process.env.REACT_APP_API_URL}/add-to-cart.php?listing_id=${listingData.id}`} className="headerLoginBtn">
              לרכישה
            </a>
          )
        )}
      </div>
    </div>
  );
};

export default ScrollingTabs;
