import getCountryIcon from "../utils/getCountryIcon";
import axios from "axios";
import { useState, useEffect } from "react";
import TimeIcon from "../assets/icons/TimeIcon";
import NisIcon from "../assets/icons/NisNcon";
import CircleMapIcon from "../assets/icons/CircleMapIcon";
import Award from "../assets/images/award.png";

const ListingSummary = ({ data, tabRef, showBadge, listingReviewCount }) => {

    const [countryData, setCountryData] = useState(null);
    const [cityName, setCityName] = useState('');

    // Function to fetch country data
    const getCountryData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/rz_country/${data?.acf?.it_rz_country[0]}`);
            if (response) {
                setCountryData(response?.data);
            }
        } catch (err) {
            console.log("Error fetching country data:", err.response);
        }
    };

    // Function to fetch city data
    const getCityData = async () => {
        try {
            const cityId = data?.acf?.it_rz_cities[0];
            if (cityId) {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/rz_cities/${cityId}`);
                if (response) {
                    setCityName(response.data?.name);
                }
            }
        } catch (err) {
            console.log("Error fetching city data:", err.response);
        }
    };


    useEffect(() => {
        if (data?.acf?.it_rz_country) {
            getCountryData();
        }
        if (data?.acf?.it_rz_cities) {
            getCityData();
        }
    }, [data]);

    return (
        <div className="listingSummary" ref={tabRef}>
            <div className="listingTitle listingMainTitle">

                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        {showBadge && (
                            <img className="panelImage" src={Award} alt="vacaytion_style" />
                        )}
                    </div>
                    <div className="listingMainTitleIcon d-flex justify-content-between align-items-center w-100">
                        <h1 className="m-0">{data?.acf?.post_title}</h1>
                        {listingReviewCount > 0 && (
                            <div className="ratingTopcircle">
                                <i className="fas fa-star"></i>
                                {listingReviewCount}
                            </div>
                        )}
                    </div>
                </div>

            </div>
            <div className="singleListingPanelSummary topSectionSingleListing">

                <hr className="w-100" />
                <ul className="topListingInfo d-flex flex-wrap align-items-center">
                    <li>
                        <TimeIcon />
                        <span>תכנון ל-{data?.acf?.trip_days} ימים</span>
                    </li>
                    <li>
                        <NisIcon />
                        <span>{data?.acf?.price_for_purchasing} ש"ח</span>
                    </li>
                    {cityName && (
                        <li>
                            <CircleMapIcon />
                            <span>{cityName}</span>
                        </li>
                    )}
                </ul>
            </div>

            <div className="iterationSummaryIcons"></div>

            <div className="aboutYourSelf">
                <div className="tabPanel">
                    <div className="singleListingPanelTitleIcon d-flex">
                        <div className="title_icon">
                            <img className="panelImage" src={`${process.env.REACT_APP_API_URL}/wp-content/themes/brikk-child/images/passport-icon.png`} alt="vacaytion_style" />
                        </div>
                        <h3>סגנון החופשות שלי</h3>
                    </div>
                    <div
                        className="singleListingPanelSummary aboutDescription"
                        dangerouslySetInnerHTML={{
                            __html: data?.acf?.rz_about_yourself_worldview_on_trips,
                        }}
                    />
                </div>
                <div className="tabPanel country_list">
                    <div className="singleListingPanelTitleIcon d-flex">
                        <div className="title_icon"
                            dangerouslySetInnerHTML={{
                                __html: countryData?.slug && getCountryIcon(countryData?.slug)
                                    ? getCountryIcon(countryData?.slug)[countryData?.slug] || ""
                                    : ""
                            }}
                        />
                        {countryData && <h3>{countryData.name}</h3>}
                    </div>

                    <div
                        className="singleListingPanelSummary aboutTrip"
                        dangerouslySetInnerHTML={{
                            __html: data?.acf?.about_the_trip,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ListingSummary;
