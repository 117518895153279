import TimeTableIcon from "../assets/icons/TimeTableIcon";
import CalendarColorIcon from "../assets/icons/calendarColorIcon";

const TimeTableTabPanel = ({data, tabRef}) => {
    return (
        <div className="tabPanel" ref={tabRef}>
            <div className="aboutTabPanel">

                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <CalendarColorIcon />
                    </div>
                    <h3>לו"ז מלא לדוגמא</h3>                    
                </div>

                <div
                    className="singleListingPanelSummary tabDescription"
                    dangerouslySetInnerHTML={{
                        __html: data?.acf?.rz_user_timetable_desc,
                    }}
                />
            </div>
        </div>
    );
}

export default TimeTableTabPanel;