import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OtherTrips from "./OtherTrips";
import PlusIcon from "../assets/images/plus-circle.svg";
import CalendarMobile from "../assets/images/calender-mobile.png";
import LocationMobile from "../assets/images/location-mobile.png";
import CartColoredIcon from "../assets/images/cart-colored-icon.png";

import EyeHeart from "../assets/images/eyeheart.svg";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import ReCAPTCHA from "react-google-recaptcha";
import AdditionalTrips from "./AdditionalTrips";

const ListingFooter = ({
    userId,
    listingData,
    showBlur,
    handleCalendarButtonClick,
    openMobileMap,
    isDisplayMobileMap,
    showCalendar,
    setIsMobileMenuOpen,
    isMobileMenuOpen,
}) => {
    const [podcastList, setPodcastList] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [menuData, setMenuData] = useState({
        general_menu: [],
        support_menu: [],
        use_site_menu: [],
        categorize_menu: [],
        others_menu: [],
    });
    const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [acceptErrorMessage, setErrorAcceptMessage] = useState("");
    const [acceptMarketing, setAcceptMarketing] = useState(false); // State for marketing checkbox
    const [captchaError, setCaptchaError] = useState(""); // State for CAPTCHA error
    const [captchaToken, setCaptchaToken] = useState(null);
    const [captchaErrorMessage, setCaptchaErrorMessage] = useState(""); // State for CAPTCHA error

    const handleCaptcha = (token) => {
        if (!token) {
            setCaptchaErrorMessage("שגיאה לבעלי האתר: דומיין לא חוקי של מפתח האתר");
        } else {
            setCaptchaToken(token);
            setCaptchaErrorMessage("");
        }
    };

    // Fetch podcast data
    const getPodcastData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/wp-json/podcast/show`
            );
            if (response.data.status === "success") {
                setPodcastList(response.data.podcast_list);
            }
        } catch (err) {
            console.log("Error fetching podcast data: ", err);
        }
    };

    // Fetch menu data
    const getMenuData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/wp-json/menu/show`
            );
            if (response.data.success) {
                setMenuData({
                    general_menu: response.data.general_menu || [],
                    support_menu: response.data.support_menu || [],
                    use_site_menu: response.data.use_site_menu || [],
                    categorize_menu: response.data.categorize_menu || [],
                    others_menu: response.data.others_menu || [],
                });
            }
        } catch (err) {
            console.log("Error fetching menu data: ", err);
        }
    };

    useEffect(() => {
        getPodcastData();
        getMenuData();
    }, []);

    const toggleFooter = () => {
        setIsActive(!isActive);
    };

    const handleSubscribe = async () => {
        setSuccessMessage("");

        if (!email) {
            setErrorMessage("שדה נדרש");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setErrorMessage("אימייל שגוי");
            return;
        }

        if (!acceptMarketing) {
            setErrorMessage("");
            setErrorAcceptMessage("יש לאשר קבלת חומר שיווקי באמצעות הסמן");
            return;
        }

        try {
            setErrorMessage("");
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/wp-json/newsletter/add`,
                { email }
            );

            if (response.data.message === "Email already exists") {
                setErrorMessage("Email already exists");
                setErrorAcceptMessage("");
            } else if (response.data.message === "Email added successfully") {
                setSuccessMessage("!תודה, כיף שהצטרפתם");
                setEmail("");
                setAcceptMarketing(false);
                setCaptchaToken(null);
                setErrorAcceptMessage("");
                setErrorMessage("");
            }
        } catch (err) {
            console.log("Error subscribing: ", err);
            if (
                err.response &&
                err.response.data &&
                err.response.data.message === "Email already exists"
            ) {
                setErrorMessage("כתובת דואל זו כבר קיימת במערכת.");
                setErrorAcceptMessage("");
            } else {
                setErrorMessage("An error occurred. Please try again later.");
                setErrorAcceptMessage("");
            }
        }
    };

    const toggleMobileMap = () => {
        openMobileMap(!isDisplayMobileMap);
    };

    const toggleSidebarCalendar = () => {
        handleCalendarButtonClick();
    };

    const openMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const bodyElement = document.querySelector("body");
        if (isDisplayMobileMap) {
            if (!bodyElement.classList.contains("openedMobileMap")) {
                bodyElement.classList.add("openedMobileMap");
            }
        } else {
            if (bodyElement.classList.contains("openedMobileMap")) {
                bodyElement.classList.remove("openedMobileMap");
            }
        }
    }, [isDisplayMobileMap]);

    useEffect(() => {
        if (showCalendar) {
            openMobileMap(false);
        }
    }, [showCalendar]);

    return (
        <>
            <div className="commonListingRowOuter">
                <div className="container">
                    <div className="commonListingCommonRow">
                        <div className="singleListingPanelTitleIcon d-flex">
                            <div className="title_icon">
                                <img src={PlusIcon} />
                            </div>
                            <h3>מפת ההמלצות אצלכם בנייד</h3>
                        </div>
                        <div className="singleListingPanelSummary">
                            <OtherTrips userId={userId} listingData={listingData} />
                        </div>
                    </div>
                    <div className="commonListingCommonRow">
                        <div className="singleListingPanelTitleIcon d-flex">
                            <div className="title_icon">
                                <img src={PlusIcon} />
                            </div>
                            <h3>המלצות נוספות</h3>
                        </div>
                        <div className="singleListingPanelSummary">
                            <AdditionalTrips userId={userId} listingData={listingData} />
                        </div>
                    </div>
                </div>
            </div>

            <footer className={`${isActive ? "active" : ""}`}>
                <div className="footerNewsletter py-5">
                    <div className="container">
                        <h2>רוצים לקבל מידע על המקומות הכי שווים בארץ ובעולם לטיולים?</h2>
                        <form>
                            <div className="footerNewsletterForm">
                                <i className="fas fa-paper-plane"></i>
                                <input
                                    type="email"
                                    name="email"
                                    className="email"
                                    placeholder="כתובת מייל"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errorMessage && (
                                    <p
                                        className="error-message mt-1"
                                        style={{ color: "#f5a688" }}
                                    >
                                        {errorMessage}
                                    </p>
                                )}
                                {successMessage && (
                                    <p
                                        className="success-message mt-1"
                                        style={{ color: "#f5a688" }}
                                    >
                                        {successMessage}
                                    </p>
                                )}
                                <ReCAPTCHA
                                    sitekey={"6LfIOEckAAAAAN80mb_WDKwPmTvbRue_qzGirbVm"}
                                    onChange={handleCaptcha}
                                    onExpired={() =>
                                        setCaptchaError(
                                            "שגיאה לבעלי האתר: דומיין לא חוקי של מפתח האתר"
                                        )
                                    }
                                />
                                {captchaErrorMessage && (
                                    <p
                                        className="error-message mt-3 text-center"
                                        style={{ color: "#f5a688" }}
                                    >
                                        {captchaErrorMessage}cccc
                                    </p>
                                )}
                                <button type="button" onClick={handleSubscribe}>
                                    <i className="fas fa-arrow-right"></i>
                                </button>
                            </div>
                        </form>

                        {/* Checkbox for marketing consent */}
                        <div className="marketing-consent mt-3">
                            <input
                                type="checkbox"
                                id="marketingConsent"
                                checked={acceptMarketing}
                                onChange={(e) => setAcceptMarketing(e.target.checked)}
                            />
                            <label htmlFor="marketingConsent">
                                אני מאשר/ת קבלת חומר שיווקי
                            </label>
                        </div>
                        {acceptErrorMessage && (
                            <p
                                className="error-message mt-3 text-center"
                                style={{ color: "#f5a688" }}
                            >
                                {acceptErrorMessage}cccc
                            </p>
                        )}
                        {/* Display CAPTCHA error */}

                        <p className="footerNewsletterSummary mt-4 text-center">
                            מתכננים חופשה בארץ או בעולם? הגעתם למקום הנכון! רשימת החברים שלנו
                            מקבלת מידע מדי חודש על המקומות הסודיים שהכי כדאי להגיע אליהם בארץ
                            ובעולם.
                        </p>
                        <ul className="footerSocial d-flex justify-content-center gap-2 mt-4 pt-3 mb-4">
                            <li className="instagram">
                                <Link
                                    to="https://www.instagram.com/vacaytions_travel?igsh=aHNnOHIyb3F1eW40"
                                    target="_blank"
                                >
                                    <i className="fab fa-instagram"></i>
                                </Link>
                            </li>
                            <li className="facebook">
                                <Link
                                    to="https://www.facebook.com/profile.php?id=100067472732993&amp;mibextid=LQQJ4d"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <i className="fab fa-facebook-f"></i>
                                </Link>
                            </li>
                            <li className="podcast">
                                <a href={`${process.env.REACT_APP_API_URL}/explore/?type=podcast-listing`} rel="noopener">
                                    <i className="fas fa-podcast" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footerPodcastListingwrap py-5">
                    <div className="container">
                        <h6 className="mb-3">פודקאסט "יוצאים לחופש"</h6>
                        <div className="footerPodcastListingInner">
                            {podcastList.map((podcast, index) => (
                                <div className="commonFtrPodcastBox" key={index}>
                                    <a href={podcast.link}>
                                        <div className="commonFtrPodcastBoxImage">
                                            <img src={podcast.image} alt={podcast.title} />
                                        </div>
                                        <h6 className="rz--name">{podcast.title}</h6>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-5">
                            <a href={`${process.env.REACT_APP_API_URL}/explore/?type=podcast-listing`} className="orangeButton">
                                לכל הפרקים
                            </a>
                        </div>
                    </div>
                </div>
                <div className="siteMainFooter py-3">
                    <div className="container">
                        <div className="siteMainFooterInner d-flex align-items-center justify-content-between">
                            <div className="copyrigthContent">
                                <p>זכויות יוצרים © 2022 VACAYTIONS</p>
                            </div>
                            <div className="footerBottomNav d-flex align-items-center">
                                <nav className="brk-nav-footerBottomNavList">
                                    <ul className="d-flex align-items-center">
                                        <li className="menu-item">
                                            <a href={`${process.env.REACT_APP_API_URL}/terms-and-conditions-and-privacy/`}>
                                                תנאים ופרטיות
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a href={`${process.env.REACT_APP_API_URL}/accessibility/`}>נגישות</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href={`${process.env.REACT_APP_API_URL}/sitemap/`}>מפת האתר</a>
                                        </li>
                                    </ul>
                                </nav>
                                <span className="footerToggleMenuBtn" onClick={toggleFooter}>
                                    מידע נוסף
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerToggleContent">
                    <span
                        className="footerToggleContentClose position-absolute "
                        onClick={toggleFooter}
                    >
                        <svg
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                        >
                            <path d="m6 6 20 20"></path>
                            <path d="m26 6-20 20"></path>
                        </svg>
                    </span>
                    <div className="container">
                        <div className="footerToggleContentInner">
                            {/* General Menu */}
                            <div className="footerToggleMenuColumn">
                                <h6>כללי</h6>
                                <ul>
                                    {menuData.general_menu.map((item, index) => (
                                        <li key={index}>
                                            <a href={item.link}>{item.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Support Menu */}
                            <div className="footerToggleMenuColumn">
                                <h6>תמיכה</h6>
                                <ul>
                                    {menuData.support_menu.map((item, index) => (
                                        <li key={index}>
                                            <a href={item.link}>{item.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Use Site Menu */}
                            <div className="footerToggleMenuColumn">
                                <h6>שימוש באתר</h6>
                                <ul>
                                    {menuData.use_site_menu.map((item, index) => (
                                        <li key={index}>
                                            <a href={item.link}>{item.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Categorize Menu */}
                            <div className="footerToggleMenuColumn">
                                <h6>קטגוריות</h6>
                                <ul>
                                    {menuData.categorize_menu.map((item, index) => (
                                        <li key={index}>
                                            <a href={item.link}>{item.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Others Menu */}
                            <div className="footerToggleMenuColumn">
                                <h6>אחרים</h6>
                                <ul>
                                    {menuData.others_menu.map((item, index) => (
                                        <li key={index}>
                                            <a href={item.link}>{item.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`footerToggleContentOverlay ${isActive ? "active" : ""}`}
                ></div>
                <div className="whatsappfixedbtn" bis_skin_checked="1">
                    <a href="https://wa.me/09876238140?text=I'm%20interested%20in%20your%20trip%20for%20sale">
                        <WhatsAppIcon />
                    </a>
                </div>
            </footer>
            <div
                className="mobpurchasebtn"
                style={{
                    display:
                        (userId == 0 || showBlur) && listingData?.id !== 17147
                            ? "block"
                            : "none",
                }}
            >
                <div className="mobpurchasebtninner d-flex align-item-center">
                    <div className="acess_rec_data acess_rec_data_mobile">
                        <a href={`${process.env.REACT_APP_API_URL}/listing/long-weekend-in-barcelona/`} target="_blank">                            
                            תכנון מלא לדוגמה
                        </a>
                    </div>
                    {userId == 0 ? (
                        <a
                            href={`${process.env.REACT_APP_API_URL}/login/?listing_id=${listingData.id}`}
                            className="for_buy_pro orangeButton"
                        >
                            <img src={CartColoredIcon} />
                            לרכישה
                        </a>
                    ) : (
                        showBlur && (
                            <a
                                href={`${process.env.REACT_APP_API_URL}/add-to-cart.php?listing_id=${listingData.id}`}
                                className="for_buy_pro orangeButton"
                            >
                                <img src={CartColoredIcon} />
                                לרכישה
                            </a>
                        )
                    )}
                </div>
            </div>

            {((!showBlur && userId !== 0) || listingData?.id === 17147) && (
                <div className="map-buttons">
                    <div className="d-flex">
                        <div className="map-buttons-inner">
                            <div
                                className="map-button map-button-fullscreen"
                                id="fullscreen-btn"
                                onClick={toggleMobileMap}
                            >
                                <img src={LocationMobile} width={20} />
                                <div className="map-button-fullscreen-txt">מפה</div>
                            </div>
                        </div>
                        <div className="map-buttons-inner">
                            <div
                                className="map-button map-button-calendar footer-calendar-btn"
                                id="calendar-btn"
                                onClick={toggleSidebarCalendar}
                            >
                                <img src={CalendarMobile} width={20} />
                                <div className="map-button-calendar-txt">יומן</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="brk-mobile-bar brk--names-no">
                <div className="brk-mobile-row">
                    <ul>
                        <li className="menu_class_Submission">
                            <a
                                href={
                                    userId === 0
                                        ? `${process.env.REACT_APP_API_URL}/login/?mode=login`
                                        : `${process.env.REACT_APP_API_URL}/submission`
                                }
                                data-name="Submission"
                            >
                                <i className="fas fa-plus"></i>
                                <span>Submission</span>
                            </a>
                        </li>
                        <li className="menu_class_Explore">
                            <a
                                href={`${process.env.REACT_APP_API_URL}/explore/?type=paid-vacation-itinerary&action=search`}
                                data-name="Explore"
                            >
                                <SearchIcon />
                                <span>Explore</span>
                            </a>
                        </li>
                        <li className="menu_class_menu" onClick={openMobileMenu}>
                            <MenuIcon />
                            <span>Menu</span>
                        </li>
                        <li className="menu_class_Messages">
                            <a
                                href={
                                    userId === 0
                                        ? `${process.env.REACT_APP_API_URL}/login/?mode=login`
                                        : `${process.env.REACT_APP_API_URL}/my-account/messages/`
                                }
                                data-name="Messages"
                            >
                                <ChatBubbleOutlineIcon />
                                <span>Messages</span>
                            </a>
                        </li>
                        <li className="brk--focusmenu_class_Profile">
                            <a
                                href={
                                    userId === 0
                                        ? `${process.env.REACT_APP_API_URL}/login/?mode=login`
                                        : `${process.env.REACT_APP_API_URL}/my-account`
                                }
                                data-name="Profile"
                            >
                                <PersonIcon />
                                <span>Profile</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ListingFooter;
